<template>
  <div class="f-file_detail_image absolute w-full h-full inset-0">
    <div v-if="isZoomed" class="zoom-indicator absolute top-14 right-4 py-1 px-4 font-bold rounded pointer-events-none z-20 text-white bg-black/40">{{zoomPercentage}}%</div>
    <div
        :class="[
          'f-file_detail_image-feature absolute w-full h-full flex-center transition-[top,left,width,height] duration-150',
          {'transition-transform': shouldTransition}
        ]"
        :style="imageFeatureStyle"
        ref="imageFeatureEl"
        @wheel.passive="onWheel">


      <file-detail-non-viewable v-if="isNonViewable || imageErrored">
        <template #image>
          <u-icon :name="statusDescriptor.icon" class="text-white text-5xl" :class="statusDescriptor.spin ? 'animate-spin' : null" />
        </template>
        <template #description>
          <h3>{{statusDescriptor.title}}</h3>
          <p>{{statusDescriptor.description}}</p>
        </template>
      </file-detail-non-viewable>

      <div v-else-if="imageLoaded" :style="{transform: `rotate(${file.images?.rotation ?? 0}deg)`}" class="transition-all duration-100" ref="imageContainerEl">
        <file-detail-image-faces v-if="file.is?.ownedByCurrentUser && isActive" :file="file" :scale="scale" :rotation="file.images?.rotation" />
        <img
          class="max-w-none"
          :src="imgSrc"
          :style="imageStyle"
          :alt="file.name"
          :draggable="false"
          onmousedown="if (event.preventDefault) event.preventDefault()"
        ><!-- note: the onmousedown handler is to prevent image dragging in firefox -->
      </div>

      <div v-else><core-busy-dots /></div>
    </div>

    <core-thumbs-roll
      ref="pagesPreviewEl"
      class="f-file_detail_image-pages-preview absolute right-0 bottom-0 left-0 bg-charcoal-700 z-20 transition-transform"
      v-if="shouldDisplayPagesPreview"
      :thumb-urls="pagePreviewUrls"
      :current-index="currentPageIndex"
      @select="selectPage"
    />

  </div>
</template>

<script setup>
  import Hammer from 'hammerjs';
  import {useImage} from '@vueuse/core';

  const updateZoomEvent = useEventBus('file-detail-update-zoom');
  const setDefaultZoomEvent = useEventBus('file-detail-set-default-zoom');

  const props = defineProps({
    file: {
      type: Object,
      required: true
    },
    availableSpace: Object,
    isActive: Boolean
  });

  const hammer = ref(null);
  const imageFeatureEl = ref();
  const pagesPreviewEl = ref();
  const currentPageIndex = ref(0);
  const availableHeightAdjustment = ref(0);
  const shouldTransition = ref(false);
  const scale = ref(1);
  const panX = ref(0);
  const panY = ref(0);
  const arePinchEventsBound = ref(false);

  //note: these trackers are needed because hammer data is cumulative until a gesture is complete
  const pinchTracker = ref(null);
  const panTracker = ref(null);

  //note: these are taken out of the imageSources computed prop in order to prevent reactivity on them, which leads to undesirable reloading of the largeImg
  const baseImageUrl = props.file.images.preview;
  const originalImageUrl = props.file.images.original || props.file.images.preview;
  const fileIsGif = props.file.is.gif;
  const fileIsDetail = computed(() => props.file.is.detail);
  const fileIsMultiPaged = computed(() => props.file.is.multiPaged);

  //image loading
  const imageSources = computed(() => {
    let largeUrl = baseImageUrl;
    let originalUrl = originalImageUrl;

    if (fileIsMultiPaged.value && fileIsDetail.value) {
      const pages = props.file.pages;
      largeUrl = `${pages.base_url}/${pages.files[currentPageIndex.value]}?${pages.query_values}`;
      originalUrl = largeUrl;
    }

    largeUrl = updateQueryParameters(largeUrl, {rotate: null});

    originalUrl = updateQueryParameters(originalUrl, {
      format: null,
      quality: null
    });

    if (fileIsGif) {
      largeUrl = updateQueryParameters(largeUrl, {format: null});
      originalUrl = updateQueryParameters(originalUrl, {format: null});
    }

    return {largeUrl, originalUrl};
  });

  const largeImageSrc = computed(() => ({src: imageSources.value.largeUrl}));
  const {state: workingImage, error: imageErrored, isLoading: workingImageLoading} = useImage(largeImageSrc);
  const imageLoaded = computed(() => workingImage.value && !workingImageLoading.value && !imageErrored.value);

  //load original image
  const originalImage = ref(null);

  async function loadOriginal() {
    if (imageSources.value.originalUrl !== imageSources.value.largeUrl) {
      const {state} = await useImage({src: imageSources.value.originalUrl});
      originalImage.value = state.value;
    }
  }

  const imgSrc = computed(() => originalImage.value ? imageSources.value.originalUrl : imageSources.value.largeUrl);
  const imageDimensions = computed(() => {
    if (originalImage.value) {
      return {
        width: originalImage.value.width,
        height: originalImage.value.height
      };
    }
    if (imageLoaded.value) {
      return {
        width: workingImage.value.width,
        height: workingImage.value.height
      };
    }
  });

  const isSideways = computed(() => Boolean(props.file.images.rotation % 180));

  const statusDescriptor = computed(() => {

    if (props.file.is.processing) {
      return {
        spin: true,
        icon: COMMON_ICONS.loading,
        title: 'File Processing',
        description: 'Preparing your file for viewing. Please wait.'
      };
    }

    if (imageErrored.value) {
      return {
        icon: 'i-ri-error-warning-fill',
        title: 'Preview Unavailable',
        description: 'Your original file is safe and can be downloaded from the link above.'
      };
    }

    if (props.file.is.errored) {
      return {
        icon: 'i-ri-error-warning-fill',
        title: 'Preview Unavailable',
        description: 'We were unable to process the file you uploaded. It is safe, and can be downloaded from the link above.'
      };
    }

    return null;
  });

  const featureDimensions = computed(() => {
    if (isNonViewable.value || imageErrored.value) {
      return {
        width: props.availableSpace.width,
        height: props.availableSpace.height
      };
    }
    if (imageDimensions.value) {
      let {width, height} = imageDimensions.value;
      if (isSideways.value) {
        [width, height] = [height, width];
      }

      return {width, height};
    }

    //todo: check this with multi paged files
    return props.file; //props.file.is.video ? props.file : props.file.images.large;
  });


  const featurePosition = useDetailFeaturePosition({
    featureDimensions,
    availableSpace: computed(() => ({
      width: props.availableSpace.width,
      height: props.availableSpace.height - availableHeightAdjustment.value
    }))
  });

  const imageFeatureStyle = computed(() => ({
    ...featurePosition.style.value,
    transform: `scale(${scale.value}) translateX(${Math.round(panX.value)}px) translateY(${Math.round(panY.value)}px)`
  }));
  const imageStyle = computed(() => {
    const [width, height] = isSideways.value
      ? [featurePosition.style.value.height, featurePosition.style.value.width]
      : [featurePosition.style.value.width, featurePosition.style.value.height];

    return {
      width,
      height
    };
  });

  //note: the greater of img vs file width - used to determine max scale and zoom ability
  //this is needed because of the mismatch between file reported dimensions and the dimensions of the loaded image
  //tends to happen with multi paged documents
  const maxFileImageWidth = computed(() => {
    const fileWidth = props.file.width || 0;
    const imageWidth = imageDimensions.value?.width || 0;

    return Math.max(fileWidth, imageWidth);
  });

  //note: maxScale is FILE_MAX_IMAGE_ZOOM_SCALE x image actual dimensions (t.maxFileImageWidth)
  const scaleAtOriginalDims = computed(() => maxFileImageWidth.value / featurePosition.coords.value.width);
  const maxScale = computed(() => scaleAtOriginalDims.value * FILE_MAX_IMAGE_ZOOM_SCALE);
  const zoomPercentage = computed(() => Math.round((scale.value * 100) / scaleAtOriginalDims.value));
  const isZoomed = computed(() => scale.value !== 1);
  const pagePreviewUrls = computed(() => props.file.pages?.files.map(pageName => updateQueryParameters(
    `${props.file.pages.base_url}/${pageName}?${props.file.pages.query_values}`,
    {
      width: FILE_THUMBS_PREVIEW.width,
      height: FILE_THUMBS_PREVIEW.height,
      crop: 'pad',
      pad_color: '262525'
    }
  )));
  const isNonViewable = computed(() => props.file.is.errored || props.file.is.processing);
  const shouldDisplayPagesPreview = computed(() => !isNonViewable.value && pagePreviewUrls.value?.length > 1);
  const hasMorePages = computed(() => props.file.is.multiPaged && currentPageIndex.value < props.file.pages_count - 1);

  watch(isZoomed, newVal => updateZoomEvent.emit({isZoomed: newVal}));

  function selectPage(index) {
    currentPageIndex.value = index;
  }

  async function adjustAvailableSpace() {
    await waitFor(300); //note: needed for pages preview to finish transitioning

    if (shouldDisplayPagesPreview.value && pagesPreviewEl.value) {
      const {height} = useElementSize(pagesPreviewEl.value.$el);

      availableHeightAdjustment.value = height.value || 0;
    } else {
      availableHeightAdjustment.value = 0;
    }
  }

  function keyDown(e) {
    if (document.body.classList.contains('modal-open')) {
      return;
    }

    const handlers = {
      ArrowLeft: () => {
        if (currentPageIndex.value) {
          e.stopPropagation();
          e.stopImmediatePropagation();
          currentPageIndex.value--;
        }
      },
      ArrowRight: () => {
        if (hasMorePages.value) {
          e.stopPropagation();
          e.stopImmediatePropagation();
          currentPageIndex.value++;
        }
      }
    };
    const inZoomHandlers = {
      ArrowLeft: () => onPan({deltaX: -10, isFinal: true}),
      ArrowRight: () => onPan({deltaX: 10, isFinal: true}),
      ArrowUp: () => onPan({deltaY: -10, isFinal: true}),
      ArrowDown: () => onPan({deltaY: 10, isFinal: true})
    };

    const handler = isZoomed.value ? inZoomHandlers[e.key] : handlers[e.key];

    if (handler) {
      if (isZoomed.value) { //special conditions apply for event propagation for regular handlers
        e.stopPropagation();
        e.stopImmediatePropagation();
      }

      handler();
    }
  }

  //zoom+pan methods
  function setPan(x, y) {
    const boundPanX = (featurePosition.coords.value.width * (scale.value - 1)) / (2 * scale.value);
    const boundPanY = (featurePosition.coords.value.height * (scale.value - 1)) / (2 * scale.value);

    panX.value = Math.max(Math.min(x, boundPanX), -boundPanX);
    panY.value = Math.max(Math.min(y, boundPanY), -boundPanY);
  }

  function zoom({x, y, scale: setScale}) {
    const newScale = Math.min(Math.max(setScale, 1), maxScale.value);
    const deltaScale = newScale / scale.value;

    const imgCenter = {
      x: (featurePosition.coords.value.width / 2),
      y: (featurePosition.coords.value.height / 2)
    };
    const distFromCenter = {
      x: (imgCenter.x - x) * scale.value,
      y: (imgCenter.y - y) * scale.value
    };

    const deltaPanX = (distFromCenter.x * (deltaScale - 1));
    const deltaPanY = (distFromCenter.y * (deltaScale - 1));

    const newPanX = ((panX.value * scale.value) + deltaPanX) / newScale;
    const newPanY = ((panY.value * scale.value) + deltaPanY) / newScale;
    scale.value = newScale;

    setPan(newPanX, newPanY);
  }

  async function setZoom({file, action}) {
    if (file.id === props.file.id) {
      shouldTransition.value = true;
      await nextTick();

      if (action === 'zoom-in') {
        scale.value = scaleAtOriginalDims.value * FILE_DEFAULT_IMAGE_ZOOM_SCALE;
      } else {
        resetZoom();
      }

      setTimeout(() => (shouldTransition.value = false), 300);
    }
  }

  function onHammerInput(e) {
    if (props.isActive && isZoomed.value) {
      e.srcEvent.stopPropagation();
    }
  }

  function onPan({deltaX = 0, deltaY = 0}) {
    if (!panTracker.value) {
      panTracker.value = {
        x: panX.value,
        y: panY.value
      };
    }

    const x = panTracker.value.x + (deltaX / scale.value);
    const y = panTracker.value.y + (deltaY / scale.value);

    setPan(x, y);
  }
  function onPanEnd() {
    panTracker.value = null;
  }

  const imageContainerEl = ref();

  function onWheel(e) {
    //e.preventDefault();

    if (!props.isActive) {
      return;
    }

    let x = e.offsetX;
    let y = e.offsetY;
    let checkOffsetElement = e.target;



    while (checkOffsetElement !== imageContainerEl.value) {
      x += checkOffsetElement?.offsetLeft;
      y += checkOffsetElement?.offsetTop;
      checkOffsetElement = checkOffsetElement?.offsetParent;
    }

    const effectiveRotation = props.file.images?.rotation % 360;

    if (effectiveRotation === 90) {
      y = e.offsetX;
      x = featurePosition.coords.value.width - e.offsetY;
    }
    if (effectiveRotation === 180) {
      x = featurePosition.coords.value.width - e.offsetX;
      y = featurePosition.coords.value.height - e.offsetY;
    }
    if (effectiveRotation === 270) {
      x = e.offsetY;
      y = featurePosition.coords.value.height - e.offsetX;
    }

    zoom({
      x,
      y,
      scale: scale.value + (-e.deltaY * 0.005)
    });
  }

  function onPinch(e) {
    if (!pinchTracker.value) {
      const featureRect = imageFeatureEl.value.getBoundingClientRect();
      pinchTracker.value = {
        x: (e.center.x - featureRect.x) / scale.value,
        y: (e.center.y - featureRect.y) / scale.value,
        prevScale: scale.value
      };
    }

    zoom({
      x: pinchTracker.value.x,
      y: pinchTracker.value.y,
      scale: pinchTracker.value.prevScale * e.scale
    });
  }
  function onPinchEnd() {
    pinchTracker.value = null;
  }

  function resetZoom() {
    scale.value = 1;
    panX.value = 0;
    panY.value = 0;
  }
  function bindZoomEvents() {
    if (isNonViewable.value || !imageLoaded.value) {
      return;
    }

    hammer.value?.get('pinch').set({enable: props.isActive});
    hammer.value?.get('pan').set({enable: isZoomed.value});

    if (props.isActive) {
      if (!arePinchEventsBound.value) {
        setDefaultZoomEvent.on(setZoom);
        arePinchEventsBound.value = true; //so we don't bind multiple times
      }
    } else {
      unbindZoomEvents();
    }
  }
  function unbindZoomEvents() {
    setDefaultZoomEvent.off(setZoom);
    arePinchEventsBound.value = false;
  }

  onMounted(() => {
    if (!props.file.is.errored) {
      adjustAvailableSpace();

      hammer.value = new Hammer(imageFeatureEl.value);
      hammer.value.on('hammer.input', onHammerInput);
      hammer.value.get('pinch').set({enable: false});
      hammer.value.get('pan').set({ enable: false});
      hammer.value.on('pinch', onPinch);
      hammer.value.on('pinchend', onPinchEnd);
      hammer.value.on('pan', onPan);
      hammer.value.on('panend', onPanEnd);
      bindZoomEvents();

      if (props.isActive) {
        document.body.addEventListener('keydown', keyDown); //note: do not throttle this so we successfully stop propagation
      }
    }
  });

  onUnmounted(() => {
    if (!props.file.is.errored && hammer.value) {
      document.body.removeEventListener('keydown', keyDown);
      hammer.value.off('hammer.input', onHammerInput);
      hammer.value.off('pinch', onPinch);
      hammer.value.off('pinchend', onPinchEnd);
      hammer.value.off('pan', onPan);
      hammer.value.off('panend', onPanEnd);
      hammer.value.destroy();
      unbindZoomEvents();
    }
  });

  watch(() => props.file, () => {
    originalImage.value = null;
    resetZoom();
  });

  watch(imageLoaded, bindZoomEvents);

  watch(() => props.isActive, newVal => {
    if (!props.file.is.errored) {
      currentPageIndex.value = 0;
      resetZoom();

      if (newVal) {
        document.body.addEventListener('keydown', keyDown); //note: do not throttle this so we successfully stop propagation
      } else {
        document.body.removeEventListener('keydown', keyDown);
      }
      bindZoomEvents();
    }
  });

  watch(shouldDisplayPagesPreview, adjustAvailableSpace);
  watch(() => props.availableSpace, () => {
    if (props.isActive) {
      adjustAvailableSpace();
    }
  });
  watch (() => props.isActive, adjustAvailableSpace);
  watch(isZoomed, newVal => {
    if (newVal && !originalImage.value) {
      loadOriginal();
    }
    bindZoomEvents();
  });
  watch(currentPageIndex, () => {
    resetZoom();
    originalImage.value = null;
  });
</script>
